<template>
    <div>
        <div class="container">
            <div class="handle-box flex" style="margin-top: 0;">
               <h2>{{scoreData.name}}评分表</h2>
               <div class="flex">
                    <h3>总得分:{{totalScore}}</h3>
                    <template v-if="!scoreData.id">
                        <el-button @click="submit" v-if="!scoreData.str||!scoreData.str.length>0" type="primary">提交</el-button>
                    </template>
               </div>
            </div>
            <el-table
                :data="tableData"
                class="table"
                border
                max-height="700"
                show-summary
                :span-method="scoreData.number&&scoreData.number==3?objectSpanMethod:''"
                :summary-method="getSummaries"
            >
                <el-table-column prop="title" v-if="scoreData.number&&scoreData.number==3" label=""></el-table-column>
                <el-table-column type="index" v-else width="50"></el-table-column>

                <el-table-column prop="name" label="项 目"></el-table-column>
                <el-table-column prop="num" label="得 分" v-if="scoreData.number&&scoreData.number==3">
                    <template #default="scope">
                        <el-input type="number" placeholder="请输入内容" v-model="scope.row.num"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="num" label="评 分" v-else>
                    <template #default="scope">
                        <el-radio-group v-model="scope.row.num" class="radioGroup">
                            <el-radio class="radioBar" v-for="(item,index) in scope.row.scoreList" :key="index" :label="item.num">{{item.name}}</el-radio><br/>
                        </el-radio-group>
                    </template>
                </el-table-column>
                
            </el-table>
            <!-- 老年性痴呆 -->
            <template v-if="scoreData.number&&scoreData.number==2">
                <div class="handle-box flex">
                    <h4>MMSE简易智能精神状态检查量表</h4>
                </div>
                <el-table class="table" :data="cd_Data" show-summary max-height="750" :summary-method="getSummaries2" :span-method="objectSpanMethod" header-cell-class-name="table-header">
                    <el-table-column prop="title" label="项目"></el-table-column>
                    <el-table-column prop="content" label="问题"></el-table-column>
                    <el-table-column prop="val" label="记录">
                        <template #default="scope">
                           <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 3}" placeholder="请输入内容" v-model="scope.row.val"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="num" label="评 分">
                        <template #default="scope">
                            <el-radio-group v-model="scope.row.num" class="radioGroup">
                                <el-radio class="radioBar" v-for="(item,index) in scope.row.scoreList" :key="index" :label="item.num">{{item.name}}</el-radio><br/>
                            </el-radio-group>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="handle-box">
                    <h3 class="content_title">操作说明</h3>
                    <h4>I.定向力（最高分：10分）</h4>
                </div>
                <div class="content_text">1，首先询问日期，之后再针对性的询问其他部分，如“您能告诉我现在是什么季节？”，每答对一题得一分。</div>
                <div class="content_text">2，请依次提问，“您能告诉我我们在什么省市吗？”（区县？街道？什么地方？第几层楼？）每答对一题得一分。</div>
                <div class="handle-box">
                    <h4>II．记忆力（最高分：3分）</h4>
                </div>
                <div class="content_text">告诉被测试者您将问几个问题来检查他/她的记忆力，然后清楚、缓慢地说出3个相互无关地东西的名称（如：皮球、国旗、树木、大约1秒钟说一个）。说完所有的3个名称之后，要求被测试者重复它们。被测试者的得分取决于他们首次重复的答案。（答对1个得1分，最多得3分）。如果他们没能完全记住，你可以重复，但重复的次数不能超过5次。如果5次后他们仍未记住所有的3个名称，那么对于回忆能力的检查就没有意义了。（请跳过IV部分“回忆能力”检查）。</div>
                <div class="handle-box">
                    <h4>III．注意力和计算力（最高分：5分）</h4>
                </div>
                <div class="content_text">要求病人从100开始减7，之后再减7，一直减5次（即93，86，79，72，65）。每答对1个得1分，如果前次错了，但下一个答案是对的，也得1分。</div>
                <div class="handle-box">
                    <h4>IV．回忆能力（最高分：3分）</h4>
                </div>
                <div class="content_text">如果前次被测试者完全记住了3个名称，现在就让他们再重复一遍。每正确重复1个得1分。最高3分。</div>
                <div class="handle-box">
                    <h4>V．语言能力（最高分：9分）</h4>
                </div>
                <div class="content_text">1. 命名能力（0-2分）：拿出手表卡片给测试者看，要求他们说出这是什么？之后拿出铅笔问他们同样的问题。</div>
                <div class="content_text">2. 复述能力（0-1分）：要求被测试者注意你说的话并重复一次，注意只允许重复一次。这句话话是“四十四只石狮子”，只有正确，咬字清楚的才记1分。</div>
                <div class="content_text">3. 三步命令（0-3分）：给被测试者一张空白的平纸，要求对方按你的命令去做，注意不要重复或示范。只有他们按正确顺序做的动作才算正确，每个正确动作计1分。</div>
                <div class="content_text">4. 阅读能力（0-1分）：拿出一张“闭上您的眼睛”卡片给被测试者看，要求被测试者读它并按要求去做。只有他们确实闭上眼睛才能得分。</div>
                <div class="content_text">5. 书写能力（0-1分）：给被测试者一张白纸，让他们自发的写出一句完整的句子。句子必须有主语、动词，并有意义。注意你不能给予任何提示。语法和标点的错误可以忽略。</div>
                <div class="content_text">6. 结构能力（0-1分）：在一张白纸上画有交叉的两个五边形，要求被测试者照样准确地画出来。评分标准：五边形需画出5个清楚地角和5个边。同时，两个五边形交叉处形成菱形。线条的抖动和图形的旋转可以忽略。</div>
                <div class="handle-box">
                    <h5>最高得分为 30分，分数在27-30分为正常，分数＜27为认知功能障碍。</h5>
                    <h5>痴呆严重程度分级方法：</h5>
                    <h5>轻度MMSE≥21分；中度，MMSE 10-20分；重度，MMSE≤9分</h5>
                </div>
                <div class="handle-box">
                    <h4>痴呆风险评分量表</h4>
                </div>
                <div class="content_text">1、年龄＜47岁，加0分；年龄47~53岁，加3分；年龄>53岁，加4分；</div>
                <div class="content_text">2、接受文化教育时间在10年以上，加0分；7~9年，加2分；0~6年，加3分。</div>
                <div class="content_text">3、男性，加1份；女性，0分。</div>
                <div class="content_text">4、收缩压＞140mmHg，加2分；收缩压＜ 140mmHg，加0分。体重指数＜ 30mg/m2，加0分。</div>
                <div class="content_text">5、总胆固醇＞6.5mmol/L（251mg/dL），加2分；如总胆固醇＜6.5mmol/L，加0分。</div>
                <div class="content_text">6、如每周至少运动2次，每次30分钟，加0分；如不经常运动，加1分。</div>
                <div class="content_text">7、未来20年里发生痴呆的风险</div>
                <el-table class="table" :data="cd_Data_pg" header-cell-class-name="table-header" style="width:800px">
                    <el-table-column prop="total" label="总分"></el-table-column>
                    <el-table-column prop="content" label="20年里发生痴呆的风险%"></el-table-column>
                </el-table>
            </template>

            <!-- 脑瘫 -->
            <template v-if="scoreData.number&&scoreData.number==3">
                <div class="handle-box">
                    <h4>评分标准</h4>
                </div>
                <div class="content_text">50 项，满分 100。</div>
                <div class="content_text">能独立完成，每项 2 分；</div>
                <div class="content_text">能独立完成，但时间较长，每项 1.5分；</div>
                <div class="content_text">能完成，但需他人辅助，每项 1 分；</div>
                <div class="content_text">2 项中完成一项或即使辅助也很困难，每项 1 分；</div>
                <div class="content_text">不能完成，每项 0 分。 </div>
                <div class="content_text">轻度障碍：75~100 分；</div>
                <div class="content_text">中度障碍：50~74 分；</div>
                <div class="content_text">重度障碍：0~49 分。</div>
                <div class="handle-box">
                    <h4>构音障碍总结表</h4>
                </div>
                <el-table class="table" :data="nt_Data" :span-method="objectSpanMethod2">
                    <el-table-column prop="title" label="项目"></el-table-column>
                    <el-table-column prop="name" label="Frenchay 评定法内容"></el-table-column>
                    <el-table-column prop="text" label="分级">
                        <el-table-column prop="level_a" label="a">
                            <template #default="scope">
                                <i class="el-icon-check" v-if="scope.row.level_a"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level_b" label="b">
                            <template #default="scope">
                                <i class="el-icon-check" v-if="scope.row.level_b"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level_c" label="c">
                            <template #default="scope">
                                <i class="el-icon-check" v-if="scope.row.level_c"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level_d" label="d">
                            <template #default="scope">
                                <i class="el-icon-check" v-if="scope.row.level_d"></i>
                            </template>
                        </el-table-column>
                        <el-table-column prop="level_e" label="e">
                            <template #default="scope">
                                <i class="el-icon-check" v-if="scope.row.level_e"></i>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="handle-box">
                    <h3 class="content_title">Frenchay 评定法</h3>
                    <h4>（每项按损伤严重程度分级，分为 a~e 五级，a 为正常，e 为严重损伤，包括 8 方面内容）</h4>
                </div>
                <template v-for="(item,index) in nt_list" :key="index">
                    <h4>{{item.user}}</h4>
                    <div class="content_text content_title">{{item.tip}}</div>
                    <div v-for="(items,index2) in item.str" :key="index2" class="content_title">
                        <div class="content_text">{{items.text}}</div>
                        <el-radio-group v-model="items.levels" class="radioBox" @change="labelBind">
                            <el-radio class="content_text" v-for="(itemss,index3) in items.textList" :label="itemss.level" :key="index3">{{itemss.listName}}</el-radio>
                        </el-radio-group>
                    </div>
                </template>
            </template>

            <!-- 帕金森 -->
            <template v-if="scoreData.number&&scoreData.number==4">
                <div class="handle-box flex">
                    <h4>修订 Hoehn 和 Yahr 分期</h4>
                </div>
                <el-table class="table" :data="pjs_Data1" max-height="730" header-cell-class-name="table-header">
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column prop="title" label="期数"></el-table-column>
                    <el-table-column prop="text" label="症状"></el-table-column>
                </el-table>
                <div class="handle-box flex">
                    <h4>Schwab 和英格兰日常生活活动量表</h4>
                </div>
                <el-table class="table" :data="pjs_Data2" max-height="730" header-cell-class-name="table-header">
                    <el-table-column type="index" width="50"></el-table-column>
                    <el-table-column prop="title" label="百分比"></el-table-column>
                    <el-table-column prop="text" label="内容"></el-table-column>
                </el-table>
            </template>
        </div>
    </div>
</template>

<script>
import scoringData from '../../../public/scoring.json'
import { getScore, addScore } from "../../api/index";
export default {
    name: "newScoring",
    data() {
        return {
            totalScore: 0,
            total_cd: 0,  // 痴呆第二个表评分
            tableData: [],   //题目数据
            scoreData: {
                
            },   //评分数据
            nt_Data: [],
            // 帕金森
            pjs_Data1: [],
            pjs_Data2: [],
            // 老年性痴呆
            cd_Data: [],
            spanArr: [],
            spanArr2: [],
            pos: null,
            pos2: null,
            nt_list: [],
            level: "e",
            order_id: '',
        };
    },
    created() {
        // console.log('路由参数',this.$route.query)
        if(this.$route.query.id){
            this.order_id = this.$route.query.id
        }
        let data = this.$route.query.data
        this.scoreData = JSON.parse(data)
        if(this.scoreData.number == 1){      //number 1:脑卒中  2：老年性痴呆   3：脑瘫   4：帕金森氏病
            this.tableData = this.deepClone(scoringData.tableData_ncz)
        }else if(this.scoreData.number == 2){
            this.tableData = this.deepClone(scoringData.tableData_cd)
            this.cd_Data = this.deepClone(scoringData.cd_Data)
            this.cd_Data_pg = scoringData.cd_Data_pg
            this.getSpanArr(this.cd_Data)
        }else if(this.scoreData.number == 3){
            this.tableData = this.deepClone(scoringData.tableData_nt)
            this.getSpanArr(this.tableData)
            this.nt_Data = this.deepClone(scoringData.nt_Data)
            this.getSpanArr2(this.nt_Data)
            this.nt_list = scoringData.nt_list
        }else if(this.scoreData.number == 4){
            this.tableData = this.deepClone(scoringData.tableData_pjs)
            this.pjs_Data1 = scoringData.pjs_Data1
            this.pjs_Data2 = scoringData.pjs_Data2
        }
        if(this.scoreData.id){
            getScore({id:this.scoreData.id}).then(res=>{
                this.tableData.map((item,index)=>{
                    if(res.str.data.list[index]){
                        item.num = Number(res.str.data.list[index])
                    }
                })
                if(this.scoreData.number == 2){
                    this.cd_Data.map((item,index)=>{
                        if(res.str.cd_Data.list[index].num){
                            item.num = Number(res.str.cd_Data.list[index].num)
                        }
                        if(res.str.cd_Data.list[index].val){
                            item.val = res.str.cd_Data.list[index].val
                        }
                    })
                }else if(this.scoreData.number == 3){
                    if(res.str.nt_Data.list.length>0){
                        this.nt_Data = res.str.nt_Data.list
                    }
                    this.labelBind2()
                }
            })
        }
    },
    methods: {
        deepClone(obj){
            let objClone =  Array.isArray(obj) ? [] : {};
            if (obj && typeof obj === 'object') {
                for(let key in obj){
                    if (obj[key] && typeof obj[key] === 'object'){
                        objClone[key] = this.deepClone(obj[key]);
                    }else{
                        objClone[key] = obj[key]
                    }
                }
            }
            return objClone;
        },
        // 编辑操作
        handleEdit(row){
            this.form = row;
            this.$router.push({path: '/drugsInfo',query:{userid:row.id}})
        },
        getSummaries(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = '合计';
                return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] += '分';
            } else {
                sums[index] = 'N/A';
            }
            });
            this.totalScore = sums[2]
            return sums;
        },
        getSummaries2(param){
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = '合计';
                return;
            }
            if (index === 1) {
                sums[index] = 'N/A';
                return;
            }
            if (index === 2) {
                sums[index] = 'N/A';
                return;
            }
            const values = data.map(item => Number(item[column.property]));
            if (!values.every(value => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                }, 0);
                sums[index] += '分';
            } else {
                sums[index] = 'N/A';
            }
            });
            this.total_cd = sums[3]
            return sums;
        },
        objectSpanMethod({rowIndex, columnIndex }){
            if (columnIndex === 0) {
                const _row = this.spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                // console.log(_row,_col)
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        objectSpanMethod2({rowIndex, columnIndex }){
            if (columnIndex === 0) {
                const _row = this.spanArr2[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                // console.log(_row,_col)
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        getSpanArr2(data) {
            this.spanArr2 = []
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr2.push(1);
                    this.pos2 = 0
                } else {
                   // 判断当前元素与上一个元素是否相同
                    if (data[i].title === data[i - 1].title) {
                        this.spanArr2[this.pos2] += 1;
                        this.spanArr2.push(0);
                    } else {
                        this.spanArr2.push(1);
                        this.pos2 = i;
                    }
                }
            }       
        },
        getSpanArr(data) {
            this.spanArr = []
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr.push(1);
                    this.pos = 0
                } else {
                   // 判断当前元素与上一个元素是否相同
                    if (data[i].title === data[i - 1].title) {
                        this.spanArr[this.pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.pos = i;
                    }
                }
            }       
        },
        labelBind(){
            let arr = []
            this.nt_list.map(item=>{
                item.str.map((items)=>{
                    arr.push(items.levels)
                })
            })
            arr.map((items,index)=>{
                if(items=="a"){
                    this.nt_Data[index].level_a = items
                }else if(items=="b"){
                    this.nt_Data[index].level_b = items
                }else if(items=="c"){
                    this.nt_Data[index].level_c = items
                }else if(items=="d"){
                    this.nt_Data[index].level_d = items
                }else if(items=="e"){
                    this.nt_Data[index].level_e = items
                }else{
                    this.nt_Data[index].level_a = ''
                    this.nt_Data[index].level_b = ''
                    this.nt_Data[index].level_c = ''
                    this.nt_Data[index].level_d = ''
                    this.nt_Data[index].level_e = ''
                }
            })
        },
        // 回显脑瘫列表
        labelBind2(){
            let arr = []
            this.nt_Data.forEach(res=>{
                if(res.level_a){
                    arr.push(res.level_a)
                }else if(res.level_b){
                    arr.push(res.level_b)
                }else if(res.level_c){
                    arr.push(res.level_c)
                }else if(res.level_d){
                    arr.push(res.level_d)
                }else if(res.level_e){
                    arr.push(res.level_e)
                }else{
                    arr.push("")
                }
            })
            let indexs = 0
            this.nt_list.forEach((item)=>{
                item.str.forEach((items)=>{
                    indexs++;
                    items.levels = arr[indexs-1]
                })
            })
        },
        submit(){
            let scoreArr = []
            this.tableData.map(item=>{
                scoreArr.push(item.num)
            })
            if(this.scoreData.number==2){
                let cd_Data = []
                this.cd_Data.map(item=>{
                    cd_Data.push({"val":item.val,"num":item.num})
                })
                this.scoreData.str.cd_Data = {
                    "total_points": this.total_cd,
                    "list": cd_Data,
                }
            }
            if(this.scoreData.number==3){
                this.scoreData.str.nt_Data = {
                    "list": this.nt_Data
                }
            }
            this.scoreData.str.data = {
                "total_points": this.totalScore,
                "list": scoreArr
            }
            this.scoreData.total_points = this.totalScore
            if(this.order_id){
                this.scoreData.order_id = this.order_id
                addScore(this.scoreData).then(()=>{
                    this.$notify({
                        title: '提示',
                        message: '新增成功',
                        type: 'success'
                    });
                    this.$router.go(-1);//返回上一层
                })
            }else{
                localStorage.setItem("scoreData", JSON.stringify(this.scoreData));
                this.$router.go(-1);//返回上一层
            }
            
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin: 20px 0;
}
.handle-box h3{
    margin-right: 20px;
}
.radioGroup{
    width: 100%;
    overflow: hidden;
    table-layout:fixed; word-break:break-all; 
}
.radioBar{
    margin-bottom: 15px;
    display: block;
    white-space:normal;
}
.content_text{
    font-size: 14px;
    line-height: 30px;
}
.content_title{
    margin-bottom: 10px;
}
.radioBox{
    display: flex;
    flex-direction: column;
}
</style>
